window.global ||= window

// ? These components function with alpine + tailwind UI components
import Swiper from 'swiper'
import {Autoplay, EffectCreative, FreeMode, Mousewheel, Navigation, Pagination, Parallax} from 'swiper/modules'

import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import vertexShader from "./glsl/vertex.glsl";
import fragmentShader from "./glsl/fragment.glsl";

window.obaia.Components = {}

window.obaia.Components.ripple = (options) => {
    let glInstance
    let animationFrameId

    return {
        imgUrl: options.imgUrl ?? null,
        init() {
            const canvas = this.$refs.klantcaseimg
            const img = this.imgUrl
            // Initialize the Gl logic here
            glInstance = {
                animate: false,
                scene: new THREE.Scene(),
                camera: new THREE.PerspectiveCamera(45, canvas.innerWidth / canvas.innerHeight, 0.1, 100),
                renderer: new THREE.WebGLRenderer({
                    canvas: canvas,
                    antialias: true,
                }),
                clock: new THREE.Clock(),
                geometry: null,
                material: null,
                mesh: null,

                initialize() {
                    this.camera.position.z = 1
                    this.renderer.setSize(canvas.previousElementSibling.clientWidth, canvas.previousElementSibling.clientHeight)
                    this.renderer.setClearColor(0xffffff, 0)
                    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
                    this.controls.enableZoom = false
                    this.onResize()
                    this.createMesh()
                    this.addEvents()

                    this.run = this.run.bind(this)
                },
                createMesh() {
                    this.geometry = new THREE.PlaneGeometry(1.5, 1, 32, 32);
                    this.material = new THREE.ShaderMaterial({
                        vertexShader,
                        fragmentShader,
                        uniforms: {
                            uTime: {value: 0.0},
                            uTexture: {value: new THREE.TextureLoader().load(img)},
                            uEffectStrength: {value: 0.2}
                        },
                        side: THREE.DoubleSide
                    })
                    this.mesh = new THREE.Mesh(this.geometry, this.material)
                    this.scene.add(this.mesh)
                },
                addEvents() {
                    window.requestAnimationFrame(this.run.bind(this))

                    // canvas.closest('.swiper').addEventListener('mouseenter', this.startAnimation.bind(this))
                    // canvas.closest('.swiper').addEventListener('mouseleave', this.stopAnimation.bind(this))

                    window.addEventListener("resize", this.onResize.bind(this), false)
                },
                startAnimation() {
                    console.log('RUNNING?')
                    this.animate = true
                    if (glInstance && glInstance.material && glInstance.material.uniforms.uEffectStrength) {
                        glInstance.material.uniforms.uEffectStrength.value = 1.0; // Enable effect
                    }
                    this.run()
                },

                stopAnimation() {
                    this.animate = false
                    if (glInstance && glInstance.material && glInstance.material.uniforms.uEffectStrength) {
                        glInstance.material.uniforms.uEffectStrength.value = 0.0; // Disable effect
                    }
                    cancelAnimationFrame(animationFrameId) // Stop the animation
                },
                run() {
                    requestAnimationFrame(this.run.bind(this))
                    this.render()

                    // if (!this.animate) return // Stop the loop if animate is false
                    // animationFrameId = requestAnimationFrame(this.run.bind(this))
                    // this.render()
                },

                render() {
                    this.material.uniforms.uTime.value = this.clock.getElapsedTime()
                    this.renderer.render(this.scene, this.camera)
                },

                onResize() {
                    const imgElement = canvas.previousElementSibling
                    const rect = imgElement.getBoundingClientRect()
                    const w = rect.width
                    const h = rect.height
                    this.camera.aspect = w / h
                    this.camera.updateProjectionMatrix()
                    this.renderer.setSize(w, h)
                },
            }

            glInstance.initialize() // Initialize your GL logic
        }
    }
}

window.obaia.Components.swiper = (options) => {
    return {
        swiperInstance: null,

        init() {
            const thisElem = this.$el
            this.swiperInstance = new Swiper(thisElem, {
                modules: [Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode, Parallax],
                autoplay: options.autoplay ?? false,
                speed: options.speed ?? 300,
                freeMode: options.freeMode ?? false,
                parallax: options.parallax ?? false,
                // cssMode: options.cssMode ?? false,
                grabCursor: options.grabCursor ?? false,
                loop: options.loop ?? false,
                autoHeight: options.autoHeight ?? false,
                slidesPerView: options.slidesPerView ?? 1,
                centeredSlides: options.centeredSlides ?? false,
                spaceBetween: options.spaceBetween ?? 16,
                initialSlide: options.initialSlide ?? 0,
                mousewheel: {
                    forceToAxis: true,
                },
                navigation: {
                    prevEl: this.$refs.swiperPrev ?? (thisElem.closest('section')?.querySelector('.js-swiper-button-prev') ?? undefined),
                    // prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
                    nextEl: this.$refs.swiperNext ?? (thisElem.closest('section')?.querySelector('.js-swiper-button-next') ?? undefined),
                },
                pagination: options.pagination ? {
                    el: thisElem.closest('section')?.querySelector('.js-swiper-pagination') || null,
                    clickable: true,
                    renderBullet: function (index, className) {
                        let names = []
                        thisElem.querySelectorAll('.swiper-slide').forEach((slide) => {
                            names.push(slide.getAttribute('data-name') || "")
                        })
                        return '<li class="cursor-pointer antialiased ' + className + '"><span class="label left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">' + names[index] + '</span></li>';
                    }
                } : {},
                breakpoints: {
                    ...options.breakpoints
                },
            })
        }
    }
}

window.obaia.Components.marquee = () => {
    let animationFrameId = null
    let shouldAnimate = false
    const throttleDelay = 33 // Throttle delay (approximately 33 = 30FPS, 16 = 60FPS)

    return {
        posX: 0,
        marqueeSpeed: 200, // Speed of the marquee (pixels per second)
        marqueeElement: null,
        marqueeWidth: 0,
        contentWidth: 0,
        clone: null,
        cloneCount: 0,
        logoItem: null,
        logoWidth: null,

        init() {
            this.marqueeElement = this.$refs.marquee
            this.marqueeWidth = this.marqueeElement.offsetWidth
            this.contentWidth = this.marqueeElement.scrollWidth

            this.logoItem = this.marqueeElement.querySelector('.js-logo-item')
            this.logoWidth = this.logoItem.offsetWidth
            // Wait for DOMContentLoaded event
            // Call handleResize initially to set up the marquee
            // document.addEventListener('DOMContentLoaded', () => console.log('DOC LOADED'))
            this.handleResize()
        },

        startAnimation() {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId)
            }

            const animate = () => {
                // this.moveMarquee()
                let isAnimating = false

                function throttle(callback, delay) {
                    let timeoutId = null

                    return function () {
                        if (!isAnimating) {
                            isAnimating = true
                            callback.apply(this, arguments)

                            timeoutId = setTimeout(() => {
                                isAnimating = false
                            }, delay)
                        }
                    }
                }

                // Wrap the moveMarquee function with the throttle function
                const throttledMoveMarquee = throttle(this.moveMarquee.bind(this), throttleDelay) // Adjust the delay as needed (e.g., 16ms for approximately 60 FPS)

                // Call the throttledMoveMarquee function instead of moveMarquee in the animation loop
                throttledMoveMarquee()

                animationFrameId = requestAnimationFrame(animate)
            }

            animationFrameId = requestAnimationFrame(animate)
        },

        stopAnimation() {
            cancelAnimationFrame(animationFrameId)
        },

        moveMarquee() {
            this.posX -= this.marqueeSpeed / 100

            if (this.posX < -this.contentWidth) {
                this.posX += this.contentWidth
            }

            this.marqueeElement.style.transform = `translate3d(${this.posX}px,0,0)`
        },

        handleResize() {
            const viewportWidth = window.innerWidth
            const shouldAnimateNew =
                this.contentWidth > this.marqueeWidth || this.contentWidth > viewportWidth

            if (shouldAnimateNew && !shouldAnimate) {
                // Start animation
                this.startAnimation()
                shouldAnimate = true

                // Remove existing clones
                this.removeClones()

                // Recreate clones based on the updated marquee width
                // const logoItem = this.marqueeElement.querySelector('.js-logo-item')
                // const logoWidth = logoItem.offsetWidth
                const clonesNeeded = Math.ceil(this.marqueeWidth / this.logoWidth) + 1

                this.cloneCount = clonesNeeded

                this.clone = this.marqueeElement.cloneNode(true)
                for (let i = 0; i < clonesNeeded; i++) {
                    if (this.marqueeElement.children[i]) {
                        this.clone.appendChild(this.marqueeElement.children[i].cloneNode(true))
                    }
                }

                this.marqueeElement.appendChild(this.clone)
            } else if (!shouldAnimateNew && shouldAnimate) {
                // Stop animation
                this.stopAnimation()
                shouldAnimate = false

                // Remove existing clones
                this.removeClones()
            }
        },

        removeClones() {
            this.marqueeElement.style.transform = 'translate3d(0px,0,0)'
            this.posX = 0
            if (this.clone) {
                this.marqueeElement.removeChild(this.clone)
                this.clone = null
                this.cloneCount = 0
            }
        }
    }
}

window.obaia.Components.listbox = function listbox(options) {
    let modelName = options.modelName || 'selected'
    let pointer = useTrackedPointer()

    return {
        init() {
            this.optionCount = this.$refs.listbox.children.length
            this.$watch('activeIndex', (value) => {
                if (!this.open) return

                if (this.activeIndex === null) {
                    this.activeDescendant = ''
                    return
                }

                this.activeDescendant = this.$refs.listbox.children[this.activeIndex].id
            })
        },
        activeDescendant: null,
        optionCount: null,
        open: false,
        activeIndex: null,
        selectedIndex: 0,
        get active() {
            return this.items[this.activeIndex]
        },
        get [modelName]() {
            return this.items[this.selectedIndex]
        },
        choose(option) {
            this.selectedIndex = option
            this.open = false
        },
        onButtonClick() {
            if (this.open) return
            this.activeIndex = this.selectedIndex
            this.open = true
            this.$nextTick(() => {
                this.$refs.listbox.focus()
                this.$refs.listbox.children[this.activeIndex].scrollIntoView({block: 'nearest'})
            })
        },
        onOptionSelect() {
            if (this.activeIndex !== null) {
                this.selectedIndex = this.activeIndex
            }
            this.open = false
            this.$refs.button.focus()
        },
        onEscape() {
            this.open = false
            this.$refs.button.focus()
        },
        onArrowUp() {
            this.activeIndex = this.activeIndex - 1 < 0 ? this.optionCount - 1 : this.activeIndex - 1
            this.$refs.listbox.children[this.activeIndex].scrollIntoView({block: 'nearest'})
        },
        onArrowDown() {
            this.activeIndex = this.activeIndex + 1 > this.optionCount - 1 ? 0 : this.activeIndex + 1
            this.$refs.listbox.children[this.activeIndex].scrollIntoView({block: 'nearest'})
        },
        onMouseEnter(evt) {
            pointer.update(evt)
        },
        onMouseMove(evt, newIndex) {
            // Only highlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = newIndex
        },
        onMouseLeave(evt) {
            // Only unhighlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = null
        },
        ...options,
    }
}

window.obaia.Components.menu = function menu(options = {open: false}) {
    let pointer = useTrackedPointer()

    return {
        init() {
            this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]'))
            this.$watch('open', () => {
                if (this.open) {
                    this.activeIndex = -1
                }
            })
        },
        activeDescendant: null,
        activeIndex: null,
        items: null,
        open: options.open,
        focusButton() {
            this.$refs.button.focus()
        },
        onButtonClick() {
            this.open = !this.open
            if (this.open) {
                this.$nextTick(() => {
                    this.$refs['menu-items'].focus()
                })
            }
        },
        onButtonEnter() {
            this.open = !this.open
            if (this.open) {
                this.activeIndex = 0
                this.activeDescendant = this.items[this.activeIndex].id
                this.$nextTick(() => {
                    this.$refs['menu-items'].focus()
                })
            }
        },
        onArrowUp() {
            if (!this.open) {
                this.open = true
                this.activeIndex = this.items.length - 1
                this.activeDescendant = this.items[this.activeIndex].id

                return
            }

            if (this.activeIndex === 0) {
                return
            }

            this.activeIndex = this.activeIndex === -1 ? this.items.length - 1 : this.activeIndex - 1
            this.activeDescendant = this.items[this.activeIndex].id
        },
        onArrowDown() {
            if (!this.open) {
                this.open = true
                this.activeIndex = 0
                this.activeDescendant = this.items[this.activeIndex].id

                return
            }

            if (this.activeIndex === this.items.length - 1) {
                return
            }

            this.activeIndex = this.activeIndex + 1
            this.activeDescendant = this.items[this.activeIndex].id
        },
        onClickAway($event) {
            if (this.open) {
                const focusableSelector = [
                    '[contentEditable=true]',
                    '[tabindex]',
                    'a[href]',
                    'area[href]',
                    'button:not([disabled])',
                    'iframe',
                    'input:not([disabled])',
                    'select:not([disabled])',
                    'textarea:not([disabled])',
                ]
                    .map((selector) => `${selector}:not([tabindex='-1'])`)
                    .join(',')

                this.open = false

                if (!$event.target.closest(focusableSelector)) {
                    this.focusButton()
                }
            }
        },

        onMouseEnter(evt) {
            pointer.update(evt)
        },
        onMouseMove(evt, newIndex) {
            // Only highlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = newIndex
        },
        onMouseLeave(evt) {
            // Only unhighlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = -1
        },
    }
}

window.obaia.Components.popoverGroup = function popoverGroup() {
    return {
        __type: 'popoverGroup',
        init() {
            let handler = (e) => {
                if (!document.body.contains(this.$el)) {
                    window.removeEventListener('focus', handler, true)
                    return
                }
                if (e.target instanceof Element && !this.$el.contains(e.target)) {
                    window.dispatchEvent(
                        new CustomEvent('close-popover-group', {
                            detail: this.$el,
                        })
                    )
                }
            }
            window.addEventListener('focus', handler, true)
        },
    }
}

window.obaia.Components.popover = function popover({open = false, focus = false} = {}) {
    const focusableSelector = [
        '[contentEditable=true]',
        '[tabindex]',
        'a[href]',
        'area[href]',
        'button:not([disabled])',
        'iframe',
        'input:not([disabled])',
        'select:not([disabled])',
        'textarea:not([disabled])',
    ]
        .map((selector) => `${selector}:not([tabindex='-1'])`)
        .join(',')

    function focusFirst(container) {
        const focusableElements = Array.from(container.querySelectorAll(focusableSelector))

        function tryFocus(element) {
            if (element === undefined) return

            element.focus({preventScroll: true})

            if (document.activeElement !== element) {
                tryFocus(focusableElements[focusableElements.indexOf(element) + 1])
            }
        }

        tryFocus(focusableElements[0])
    }

    return {
        __type: 'popover',
        open,
        init() {
            if (focus) {
                this.$watch('open', (open) => {
                    if (open) {
                        this.$nextTick(() => {
                            focusFirst(this.$refs.panel)
                        })
                    }
                })
            }

            let handler = (e) => {
                if (!document.body.contains(this.$el)) {
                    window.removeEventListener('focus', handler, true)
                    return
                }
                let ref = focus ? this.$refs.panel : this.$el
                if (this.open && e.target instanceof Element && !ref.contains(e.target)) {
                    let node = this.$el
                    while (node.parentNode) {
                        node = node.parentNode
                        if (node.__x instanceof this.constructor) {
                            if (node.__x.$data.__type === 'popoverGroup') return
                            if (node.__x.$data.__type === 'popover') break
                        }
                    }
                    this.open = false
                }
            }

            window.addEventListener('focus', handler, true)
        },
        onEscape() {
            this.open = false
            if (this.restoreEl) {
                this.restoreEl.focus()
            }
        },
        onClosePopoverGroup(e) {
            if (e.detail.contains(this.$el)) {
                this.open = false
            }
        },
        toggle(e) {
            this.open = !this.open
            if (this.open) {
                this.restoreEl = e.currentTarget
            } else if (this.restoreEl) {
                this.restoreEl.focus()
            }
        },
    }
}

window.obaia.Components.radioGroup = function radioGroup({initialCheckedIndex = 0} = {}) {
    return {
        value: undefined,
        active: undefined,
        init() {
            let options = Array.from(this.$el.querySelectorAll('input'))

            this.value = options[initialCheckedIndex]?.value

            for (let option of options) {
                option.addEventListener('change', () => {
                    this.active = option.value
                })
                option.addEventListener('focus', () => {
                    this.active = option.value
                })
            }

            window.addEventListener(
                'focus',
                () => {
                    console.log('Focus change')
                    if (!options.includes(document.activeElement)) {
                        console.log('HIT')
                        this.active = undefined
                    }
                },
                true
            )
        },
    }
}

window.obaia.Components.tabs = function tabs() {
    return {
        selectedIndex: 0,
        onTabClick(event) {
            if (!this.$el.contains(event.detail)) return

            let tabs = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
            let panels = Array.from(this.$el.querySelectorAll('[x-data^="Components.tabPanel("]'))

            let idx = tabs.indexOf(event.detail)
            this.selectedIndex = idx

            window.dispatchEvent(
                new CustomEvent('tab-select', {
                    detail: {
                        tab: event.detail,
                        panel: panels[idx],
                    },
                })
            )
        },
        onTabKeydown(event) {
            if (!this.$el.contains(event.detail.tab)) return

            let tabs = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
            let tabIndex = tabs.indexOf(event.detail.tab)

            if (event.detail.key === 'ArrowLeft') {
                this.onTabClick({detail: tabs[(tabIndex - 1 + tabs.length) % tabs.length]})
            } else if (event.detail.key === 'ArrowRight') {
                this.onTabClick({detail: tabs[(tabIndex + 1) % tabs.length]})
            } else if (event.detail.key === 'Home' || event.detail.key === 'PageUp') {
                this.onTabClick({detail: tabs[0]})
            } else if (event.detail.key === 'End' || event.detail.key === 'PageDown') {
                this.onTabClick({detail: tabs[tabs.length - 1]})
            }
        },
    }
}

window.obaia.Components.tab = function tab(defaultIndex = 0) {
    return {
        selected: false,
        init() {
            let tabs = Array.from(
                this.$el
                    .closest('[x-data^="Components.tabs("]')
                    .querySelectorAll('[x-data^="Components.tab("]')
            )
            this.selected = tabs.indexOf(this.$el) === defaultIndex
            this.$watch('selected', (selected) => {
                if (selected) {
                    this.$el.focus()
                }
            })
        },
        onClick() {
            window.dispatchEvent(
                new CustomEvent('tab-click', {
                    detail: this.$el,
                })
            )
        },
        onKeydown(event) {
            if (['ArrowLeft', 'ArrowRight', 'Home', 'PageUp', 'End', 'PageDown'].includes(event.key)) {
                event.preventDefault()
            }

            window.dispatchEvent(
                new CustomEvent('tab-keydown', {
                    detail: {
                        tab: this.$el,
                        key: event.key,
                    },
                })
            )
        },
        onTabSelect(event) {
            this.selected = event.detail.tab === this.$el
        },
    }
}

window.obaia.Components.tabPanel = function tabPanel(defaultIndex = 0) {
    return {
        selected: false,
        init() {
            let panels = Array.from(
                this.$el
                    .closest('[x-data^="Components.tabs("]')
                    .querySelectorAll('[x-data^="Components.tabPanel("]')
            )
            this.selected = panels.indexOf(this.$el) === defaultIndex
        },
        onTabSelect(event) {
            this.selected = event.detail.panel === this.$el
        },
    }
}

function useTrackedPointer() {
    /** @type {[x: number, y: number]} */
    let lastPos = [-1, -1]

    return {
        /**
         * @param {PointerEvent} evt
         */
        wasMoved(evt) {
            let newPos = [evt.screenX, evt.screenY]

            if (lastPos[0] === newPos[0] && lastPos[1] === newPos[1]) {
                return false
            }

            lastPos = newPos
            return true
        },

        /**
         * @param {PointerEvent} evt
         */
        update(evt) {
            lastPos = [evt.screenX, evt.screenY]
        },
    }
}