precision mediump float;

varying vec2 vUv;
varying float vWave;
uniform sampler2D uTexture;
uniform float uEffectStrength;

void main() {
  // Modulate the wave effect by uEffectStrength
  float wave = vWave * 0.25 * uEffectStrength; // This line is modified
  vec3 texture = texture2D(uTexture, vUv + wave).rgb;
  gl_FragColor = vec4(texture, 1.);
}